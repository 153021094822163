.remove {
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    font-size: 11px;
}

.result-title {
    color: #5B6577;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 31px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.3px;
    display: block;
    margin: 50px auto 25px;
    scroll-margin-top: 240px;
}

.result-treat {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}

.check-con {
    position: relative;
    border-radius: 12px;
    background: #F4F4F4;
    box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
    width: 160px;
    /* height: 170px; */
    margin: 32px auto;
    max-width: 42%;
}

.check-con img {
    position: absolute;
    top: -50px;
    margin: auto;
    left: 0;
    right: 0;
}

.result-treat .check-con button {
    color: #5B6577;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-style: normal !important;
    font-weight: 400;
    line-height: 28px;
    text-transform: lowercase !important;
    min-width: 100px;
    width: 140px;
    max-width: 85%;
}

.result-treat .check-con button:hover {
    box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF !important;
}

.bg {
    padding: 4px;
    border-radius: 18px;
    background: #FFFFFF;
    box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
    color: #5B6577;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-style: normal!important;
    font-weight: 400;
    line-height: 35px;
    text-transform: lowercase !important;
    min-width: 100px;
    width: 140px;
    max-width: 85%;
    margin: auto;
}

.bg div {
    background: #F4F4F4;
    border-radius: 18px;
}

.bg div div {
    background-image: linear-gradient(to left, #E8CB65, #A6D866);
    background-size: 100%;
    background-repeat: no-repeat;
    height: 20px;
    width: 100%;
}
