body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
}

.loading-points {
  height: 15px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-points:before, .loading-points:after, .loading-points span {
  content: '';
  border: 5px solid grey;
  background: grey;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50px;
  margin: 3px;
  animation: 0.6s fade-point linear infinite;
  max-width: 10px;
 }

.loading-points:before {
  animation-delay: 0s;
}

.loading-points span {
    animation-delay: .2s;
  }

.loading-points:after {
   animation-delay: .4s;
 }

@keyframes fade-point {
  50% {  opacity: 0  }
}


/* The typing effect */
@keyframes typing {
  from { width: 0; white-space: nowrap; }
  to { width: 100%; white-space: nowrap; }
}
