
.pub-img {
    /*height: 100vh;*/
    /*width: 100%;*/
    position: relative;
    /*z-index: 99999;*/
    /*left: 0;*/
    /*right: 0;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*margin: auto;*/
    /*padding: 0;*/
    /*background: white;*/
}

.selfy-img img.pub {
    display: block;
    margin: 75px auto 35px;
    max-width: 90%;
}

.pub-img .num {
    display: block;
    margin: 50px auto 25px;
    color: #5B6577;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.pub-img .treatment-analysis {
    display: block;
    margin: auto;
    width: fit-content;
    color: #5B6577;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
}

.pub-img .treatment-name {
    border: 0;
    padding: 8px 16px;
    margin: 30px auto;
    display: block;
    font-size: 24px !important;
}

progress[value] {
    width: 250px;
    height: 35px;
    margin: auto;
    display: block;
    mix-blend-mode: difference;
}

.progress-bar {
    border-radius: 2px;
    width: 250px;
    height: 22px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    padding: 4px 0;
    text-align: center;
}

.progress-bar > span {
    mix-blend-mode: difference;
    color: white;
    border-radius: 2px;
    font-size: 10px;
}

.offre-prep span {
    border-radius: 13px !important;
    background-color: #F4F4F4 !important;
    box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
    color: #5B6577 !important;
    font-size: 18px !important;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    width: 70% !important;
    margin: 25px auto;
    text-align: left;
    min-height: 60px;
    display: flex;
    align-items: center;
    padding: 15px;
}
