#container_vid {
    position: fixed;
    transition: transform .5s ease-in;
    transform: translateY(1000%);
    will-change: transform;
    max-width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    top: 10%;
    z-index: 9999;
}

#vid {
    border-radius: 50%;
    box-shadow: 0 20px 16px 500px rgb(255 255 255), -6px -6px 7px 0 #FFF;
    height: 60%;
    width: 100%;
    margin: auto;
    background: white;
}
#vid-text {
    margin: auto !important;
}
